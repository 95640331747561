<template>
  <div class="tags">
    <a :class="type == 3 ? 'focus' : ''" title="热门" href="/matches/sport-3"
      >热门</a
    >
    <a :class="type == 4 ? 'focus' : ''" title="全部" href="/matches/sport-4"
      >全部</a
    >
    <a :class="type == 1 ? 'focus' : ''" title="足球" href="/matches/sport-1"
      >足球</a
    >
    <a :class="type == 2 ? 'focus' : ''" title="蓝球" href="/matches/sport-2"
      >蓝球</a
    >
    <a
      :class="type == 'NBA' ? 'focus' : ''"
      title="NBA"
      href="/matches/comp-NBA"
      >NBA</a
    >
    <a
      :class="type == 'CBA' ? 'focus' : ''"
      title="CBA"
      href="/matches/comp-CBA"
      >CBA</a
    >
    <a
      :class="type == '英超' ? 'focus' : ''"
      title="英超"
      href="/matches/comp-英超"
      >英超</a
    >
    <a
      :class="type == '法甲' ? 'focus' : ''"
      title="法甲"
      href="/matches/comp-法甲"
      >法甲</a
    >
    <a
      :class="type == '亚洲杯' ? 'focus' : ''"
      title="亚洲杯"
      href="/matches/comp-亚洲杯"
      >亚洲杯</a
    >
    <a
      :class="type == '法协杯' ? 'focus' : ''"
      title="法协杯"
      href="/matches/comp-法协杯"
      >法协杯</a
    >
    <a
      :class="type == '英超联' ? 'focus' : ''"
      title="英超联"
      href="/matches/comp-英超联"
      >英超联</a
    >
    <a
      :class="type == '韩K联' ? 'focus' : ''"
      title="韩K联"
      href="/matches/comp-韩K联"
      >韩K联</a
    >
    <a
      :class="type == '解放者杯' ? 'focus' : ''"
      title="解放者杯"
      href="/matches/comp-解放者杯"
      >解放者杯</a
    >
    <a
      :class="type == '中超' ? 'focus' : ''"
      title="中超"
      href="/matches/comp-中超"
      >中超</a
    >
    <a
      :class="type == '美职联' ? 'focus' : ''"
      title="美职联"
      href="/matches/comp-美职联"
      >美职联</a
    >
    <a
      :class="type == '德乙' ? 'focus' : ''"
      title="德乙"
      href="/matches/comp-德乙"
      >德乙</a
    >
    <a
      :class="type == '世界杯' ? 'focus' : ''"
      title="世界杯"
      href="/matches/comp-世界杯"
      >世界杯</a
    >
    <a
      :class="type == '欧洲杯' ? 'focus' : ''"
      title="欧洲杯"
      href="/matches/comp-欧洲杯"
      >欧洲杯</a
    >
    <a
      :class="type == '荷甲' ? 'focus' : ''"
      title="荷甲"
      href="/matches/comp-荷甲"
      >荷甲</a
    >
    <a
      :class="type == '葡超' ? 'focus' : ''"
      title="葡超"
      href="/matches/comp-葡超"
      >葡超</a
    >
  </div>
</template>
        
<script setup>
import { ref } from "vue";
import { useRoute } from "vue-router";

const type = ref("3");
const route = useRoute();

if (route.params.id != undefined) {
  const types = route.params.id.split("-");
  type.value = types[1];
}
</script>
  
<style scoped>
.tags {
  display: flex;
  justify-items: center;
  align-items: center;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.tags::-webkit-scrollbar {
  display: none;
}
a {
  border-radius: 4px;
  background-color: white !important;
  color: black;
  border: 0.5px solid #c7c7c7;
  height: 23px;
  padding: 4px 12px;
  font-size: 12px;
  margin: 5px 7px;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
}
.focus {
  color: #e84b5b;
  border: 0.5px solid var(--unnamed, #e84b5b);
}
</style>