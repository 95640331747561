<template>
  <div class="match">
    <div class="date">
      <div class="state">
        <p class="p1">{{ item.match_detail_status }}</p>
        <p class="slash"></p>
        <p class="p2">{{ item.comp }}</p>
      </div>
      <div class="time">
        {{ format_date(item.match_time) }} {{ format_time(item.match_time) }}
      </div>
    </div>
    <div class="team">
      <div>
        <p class="p1"><img :src="item.home_log" /></p>
        <p class="p2">{{ item.home }}</p>
      </div>
      <div>
        <p class="p1"><img :src="item.away_log" /></p>
        <p class="p2">{{ item.away }}</p>
      </div>
    </div>
    <div class="score">
      <p>{{ item.home_score }}</p>
      <p>{{ item.away_score }}</p>
    </div>
    <div class="live">
      <p>
        <a
          v-if="anchor_id == ''"
          target="_blank"
          :href="
            'http://www.ktasj.com/scheduleDetail/' +
            item.match_id +
            '/1?navTag=1'
          "
        >
          <img src="@/assets/images/hd-live.png" />
        </a>
        <a
          v-if="anchor_id != ''"
          target="_blank"
          :href="'http://www.ktasj.com/liveMatchesTwo/' + anchor_id"
        >
          <img src="@/assets/images/hd-live.png" />
        </a>
      </p>
      <!-- <p>
          <a href="https://tv.cctv.com/" target="_blank"
            ><img src="@/assets/images/cctv.png"
          /></a>
        </p>
        <p>
          <a
            href="https://www.miguvideo.com/mgs/website/prd/sportMatchDetail.html"
            target="_blank"
            ><img src="@/assets/images/migu.png"
          /></a>
        </p> -->
      <p>
        <a href="https://sports.qq.com/kbsweb/" target="_blank"
          ><img src="@/assets/images/tencent.png"
        /></a>
      </p>
    </div>
    <div class="more" @click="detail(item.match_id)">
      <span>更</span>
      <span>多</span>
      <img src="@/assets/images/down-arrow-small.png" />
    </div>
  </div>

  <!-- <div class="down-arrow" @click="_show = !_show">
      <img src="@/assets/images/down-arrow.png" />
    </div> -->
  <div class="more-live" v-if="_show">
    <p>
      <img src="@/assets/images/hd-live.png" />
    </p>
    <p><img src="@/assets/images/cctv.png" /></p>
    <p><img src="@/assets/images/migu.png" /></p>
    <p><img src="@/assets/images/tencent.png" /></p>
    <p><img src="@/assets/images/tencent.png" /></p>
  </div>
</template>
    
<script setup>
import { ref } from "vue";
import { useIonRouter } from "@ionic/vue";
const router = useIonRouter();
const detail = (id) => {
  router.push("/detail/" + id);
};

const props = defineProps({
  item: Object,
  show: Boolean,
});
// const _show = ref(props.show);

const anchor_id = ref("");
if (props.item.anchor_ids != null) {
  const ids = props.item.anchor_ids.split(",");
  anchor_id.value = ids[0];
}

// const detail = (id) => {
//   const router = useRouter();
//   router.push({ path: "/live/" + id });
// };

function add0(m) {
  return m < 10 ? "0" + m : m;
}
function format_date(time) {
  // Create a new JavaScript Date object based on the timestamp
  // multiplied by 1000 so that the argument is in milliseconds, not seconds.
  const date = new Date(time * 1000);
  const month_dict = {
    January: 1,
    February: 2,
    March: 3,
    April: 4,
    May: 5,
    June: 6,
    July: 7,
    August: 8,
    September: 9,
    October: 10,
    November: 11,
    December: 12,
  };
  const day = date.getDate();
  const month = date.getMonth() + 1;

  // Will display time in 10:30:23 format
  return month.toString() + "-" + day;
}
function format_time(time) {
  // Create a new JavaScript Date object based on the timestamp
  // multiplied by 1000 so that the argument is in milliseconds, not seconds.
  const date = new Date(time * 1000);
  // Hours part from the timestamp
  const hours = date.getHours();
  // Minutes part from the timestamp
  const minutes = "0" + date.getMinutes();
  // Seconds part from the timestamp
  const seconds = "0" + date.getSeconds();

  return hours + ":" + minutes.substr(-2);
}
</script>
<style scoped>
.match {
  display: flex;
  flex-direction: row;
  background-color: white;
  width: 100%;
  height: 90px;
  margin-top: 3px;
  justify-content: space-around;
  justify-items: center;
  align-content: center;
  align-items: center;
}
.match div {
  display: flex;
  margin: 0px;
  height: 100%;
  justify-content: space-around;
  align-items: center;
}
.match p {
  /* height: 20px; */
  margin: 0px;
}
.date {
  flex-direction: column;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  margin: 0px 5px;
  height: 100%;
  width: 40%;
}
.date .p2 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 50%;
}
.date .time {
  color: var(--666, #666);
  text-align: right;
  font-size: 12px;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 90%;
  justify-content: flex-start;
}
.state {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
}
.state .p1 {
  color: var(--unnamed, #e84b5b);
  text-align: center;
  font-size: 10px;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 0px 37px 37px 0px;
  background: rgba(232, 75, 91, 0.05);
  padding: 3px;
}
.state .p2 {
  color: var(--333, #333);
  font-size: 12px;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  /* padding: 7px 0px; */
}
.state .slash {
  border-right: 1px solid #ccc;
  height: 30%;
  margin: 0px;
}
.team {
  display: flex;
  flex-direction: column;
  width: 20%;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  justify-items: center;
  flex-direction: column;
  margin-left: 10px !important;
}
.team .p1 img {
  width: 20px;
  height: 20px;
}
.team .p2 {
  color: var(--unnamed, #333);
  font-size: 12px;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 107px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.score {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  justify-items: center;
  color: var(--unnamed, #e84b5b);
  text-align: center;
  font-size: 12px;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 10%;
  margin-left: 10px !important;
}
.live {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 15%;
}
.live p {
  margin: 0px;
  padding: 0px;
}
.live p img {
  width: 40px;
  height: 30px;
}
.more {
  /* display: flex; */
  padding: 17px 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--unnamed, #1890ff);
  font-size: 10px;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background-color: rgba(24, 144, 255, 0.05);
  margin: 10px !important;
  height: 95% !important;
}
.more img {
  width: 10px;
  height: 10px;
}
.more-live {
  background-color: #fafafa;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end !important;
  padding-right: 40px;
}
.more-live p {
  margin: 5px 10px;
  color: var(--333, #333);
  font-size: 10px;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.more-live p img {
  width: 60px;
  height: 60px;
  border-radius: 4px;
}
</style>