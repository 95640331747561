import * as CryptoJS from "crypto-js";

// const encryptedStr = "HcXOEd1yazsGWTkBUZnbOwYYAv4l7ma86m%2FHkKIt6SZkdKJAwRLgwbGcdnnnQ2kc16Dec43cyzJHAqcGGZivPwT30MjAfNn4RYdyjfY705ztgyh0j84Ng7lZ71AKhsvXPnhHU3wmYDtW0XX%2BAHxfQ6XD91wo1EubiytQ4cHKLR8XyduTokZbiN69pkvOokJf%2BH9OOG3DGUmQVb6AHcoZoI0%2FjiwPlQOfj7%2BMmzxlmyGNHNkoH%2B9ae4JKD8iX9zDyFN%2Fa5gftcZ855039O3lACNTkYGBkyQ0cb3eUt9rSEG4foE9Gp8vvb3Kwb7vxamU1JDb1cteqZRpziCzwDapmbgDsqioaqHvvUs%2F6GRvMnvttshh2QPyF3hhWVDZ%2FUp8SZARmQAuVc8fpg6OZ9zsHraoaiVaasLXRpqGwmyYuE8bFajvG1uumOYh2tvd14JippAZZUeCoCH8P6jjAvqFvSmXsZzJdM3R9ONuqXim%2Bsh8oBu9xiVwFgHQmkS0e6s4Bv0cCSw4IADTG6v8siVgrgNBopp2rF3H1wmUxPKyxMVj0zD29RlMjKoDBGaoF1B2SoAmM6PDV8C8YuNG%2B23Tn74k%2Fm38W0A0g0fn6lI2KwdqS4GwWNi%2FIpEM%2FdUifYobLB%2BwgPv4RfuTJ%2Fheecyell2zVaILoj860%2Be%2BXzyjQsMp3VCxH9LCQiFgsAoZchjDfCay0wpfN7PbzRqWCNjunjZyygGHmpFd2s5t8jsi6q0x5Hj4kpJu0SS4w6V%2FqLjQpNdYgV%2FfiPrUg%2BC84ko6Jf3OHNclcU33sp1VITQIHcUfev4u12uxsksX7n%2F%2Fkf3iy64A4D34D"

// 解密函数
export const aesDecode = (encryptedStr) => {
  const encodedStrLast = encryptedStr.slice(-8);
  const newnewencodeStrStr = encryptedStr.slice(0, -8);
  const decodedStr = decodeURIComponent(newnewencodeStrStr);
  const key = "ADKOPSTF%2003.40.51.jpeg" + encodedStrLast;
  const iv = "ADKOPSTF%2003.40"; // 初始化向量
  const decryptedBytes = CryptoJS.AES.decrypt(
    decodedStr,
    CryptoJS.enc.Utf8.parse(key),
    {
      iv: CryptoJS.enc.Utf8.parse(iv),
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }
  );
  const res = decryptedBytes.toString(CryptoJS.enc.Utf8).trim();
  console.log("=========解密后=======>", JSON.parse(res));
  return JSON.parse(res);
};
