<template>
  <div class="match" @click="detail(item.match_id)">
    <div class="state">
      <p class="event">{{ item.comp }}</p>
      <p class="time">{{ format_time(item.match_time) }}</p>
      <p class="at">{{ item.match_detail_status }}</p>
    </div>
    <div class="info">
      <p class="team">
        <span
          v-if="item.f_home_scor != null && item.f_home_score.red_card > 0"
          class="red"
          >{{ item.f_home_score.red_card }}</span
        >
        <span
          v-if="item.f_home_scor != null && item.f_home_score.yellow_card > 0"
          class="yellow"
        >
          {{ item.f_home_score.yellow_card }}</span
        >
        {{ item.home }}
      </p>
      <p class="team-logo"><img :src="item.home_log" /></p>
      <p class="score">{{ item.home_score }} - {{ item.away_score }}</p>
      <p class="team-logo"><img :src="item.away_log" /></p>
      <p class="team">
        {{ item.away }}
        <span
          v-if="item.f_home_scor != null && item.f_away_score.yellow_card > 0"
          class="yellow"
        >
          {{ item.f_home_score.yellow_card }}</span
        >
        <span
          v-if="item.f_home_scor != null && item.f_away_score.red_card > 0"
          class="red"
          >{{ item.f_home_score.red_card }}</span
        >
      </p>

      <p class="video-live">
        <a
          v-if="anchor_id == ''"
          target="_blank"
          :href="
            'http://www.ktasj.com/scheduleDetail/' +
            item.match_id +
            '/1?navTag=1'
          "
        >
          <img src="@/assets/images/video.png" />
        </a>
        <a
          v-if="anchor_id != ''"
          target="_blank"
          :href="'http://www.ktasj.com/liveMatchesTwo/' + anchor_id"
        >
          <img src="@/assets/images/video.png" />
        </a>
      </p>
    </div>
  </div>
</template>
    
<script setup>
import { ref } from "vue";
import { useIonRouter } from "@ionic/vue";
const router = useIonRouter();

const props = defineProps({
  item: Object,
  show: Boolean,
});

const anchor_id = ref("");
if (props.item.anchor_ids != null) {
  const ids = props.item.anchor_ids.split(",");
  anchor_id.value = ids[0];
}
const detail = (id) => {
  router.push("/detail/" + id);
};
// const _show = ref(props.show);

// const anchor_id = ref("");
// if (props.item.anchor_ids != null) {
//   let ids = props.item.anchor_ids.split(",");
//   anchor_id.value = ids[0];
// }

// const detail = (id) => {
//   const router = useRouter();
//   router.push({ path: "/live/" + id });
// };

function add0(m) {
  return m < 10 ? "0" + m : m;
}
function format_date(time) {
  // Create a new JavaScript Date object based on the timestamp
  // multiplied by 1000 so that the argument is in milliseconds, not seconds.
  const date = new Date(time * 1000);
  const month_dict = {
    January: 1,
    February: 2,
    March: 3,
    April: 4,
    May: 5,
    June: 6,
    July: 7,
    August: 8,
    September: 9,
    October: 10,
    November: 11,
    December: 12,
  };
  const day = date.getDate();
  const month = date.getMonth() + 1;

  // Will display time in 10:30:23 format
  return month.toString() + "-" + day;
}
function format_time(time) {
  // Create a new JavaScript Date object based on the timestamp
  // multiplied by 1000 so that the argument is in milliseconds, not seconds.
  const date = new Date(time * 1000);
  // Hours part from the timestamp
  const hours = date.getHours();
  // Minutes part from the timestamp
  const minutes = "0" + date.getMinutes();
  // Seconds part from the timestamp
  const seconds = "0" + date.getSeconds();

  return hours + ":" + minutes.substr(-2);
}
</script>
<style scoped>
.match {
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 100%;
  height: 80px;
  margin: 2px;
  justify-content: space-between;
}
.state {
  display: flex;
  width: 55%;
  justify-content: flex-start;
  align-items: center;
  height: 50%;
  margin-left: 5%;
}
.state .event {
  color: var(--unnamed, #333);
  font-size: 10px;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 70px;
}
.state .time {
  color: var(--unnamed, #333);
  font-size: 10px;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 10px;
}
.state .at {
  display: center;
  color: #999;
  text-align: center;
  font-size: 10px;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  justify-content: center;
  width: 50px;
  margin: auto;
}
.info {
  display: flex;
  width: 90%;
  height: 50%;
  justify-content: space-between;
  justify-items: center;
  align-content: center;
  align-items: center;
  margin: auto;
}
.team-logo img {
  width: 22px;
  height: 22px;
}
.score {
  display: flex;
  width: 68px;
  justify-content: center;
  width: 100px;
}
.video-live img {
  width: 16px;
  height: 16px;
}
.match p {
  /* height: 20px; */
  margin: 0px;
}
.date {
  flex-direction: column;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  margin: 0px 5px;
  height: 100%;
  width: 40%;
}
.date .p2 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 50%;
}
.date .time {
  color: var(--666, #666);
  text-align: right;
  font-size: 12px;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 90%;
  justify-content: flex-start;
}
.state .p1 {
  color: var(--unnamed, #e84b5b);
  text-align: center;
  font-size: 10px;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 0px 37px 37px 0px;
  background: rgba(232, 75, 91, 0.05);
  padding: 3px;
}
.state .p2 {
  color: var(--333, #333);
  font-size: 12px;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  /* padding: 7px 0px; */
}
.state .slash {
  border-right: 1px solid #ccc;
  height: 30%;
  margin: 0px;
}
.team {
  color: var(--unnamed, #333);
  font-size: 10px;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100px;
}
.red {
  padding: 0px 2px;
  height: 12px;
  background-color: rgba(246, 77, 92, 1);
  color: #fff;
  text-align: center;
  font-family: DINPro;
  font-size: 9.338px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 3px;
}
.yellow {
  padding: 0px 2px;
  height: 12px;
  color: #fff;
  text-align: center;
  font-family: DINPro;
  font-size: 9.338px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background-color: rgba(253, 204, 72, 1);
  margin: 3px;
}
</style>